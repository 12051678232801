<script>
import pessoasService from '@/services/Pessoas/pessoasService';
import authenticationService from '@/services/Authentication/authenticationService';

import Vue from 'vue';
import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect);

import { PessoaCaracteristica, PessoaCaracteristicas, CWLFirebaseError } from '@/store/cwl_enums';

import {vueTopprogress} from 'vue-top-progress';

import birthDatepicker from 'vue-birth-datepicker';
import Password from 'vue-password-strength-meter';
import logsService from '@/services/Logs/logsService';

export default {
  data() {
    return {
      pessoa: {
        telefone: {},
        documento: {},
        email: {},
        dados_complementares: {},
      },
      cachedPessoa: {
        telefone: {},
        documento: {},
        email: {},
        dados_complementares: {},
      },
      birthday: '',
      password: '',
      telefone: '',
      telefone_original: '',

      tipos_usuario: PessoaCaracteristicas,
      tipo_usuario: '',

      Deselect: {
        render: createElement => createElement('span', '❌'),
      },
    };
  },
  components: {
    vueTopprogress,
    birthDatepicker,
    Password,
  },
  computed: {
    pessoaId() {
      return this.$route.params.id;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  mounted() {
    this.pessoa = this.$CWLNewPessoaPadrao();
    this.cachedPessoa = this.$CWLNewPessoaPadrao();
    this.getPessoa();
  },
  methods: {
    async getPessoa() {
      this.$refs.topProgress.start();

      try {
        const pessoaFirebase = await pessoasService.getPessoa(this.pessoaId);
        this.pessoa = pessoaFirebase.data();
        this.pessoa.id = pessoaFirebase.id;
        this.cachedPessoa = pessoaFirebase.data();
        this.cachedPessoa.id = pessoaFirebase.id;

        this.birthday = this.pessoa.dados_complementares.birthday;
        this.telefone = this.pessoa.telefone.telefone;
        this.telefone_original = this.pessoa.telefone.telefone;

        this.tipo_usuario = this.$CWLDescricaoCaracteristica(this.pessoa.caracteristicas);

        this.$refs.topProgress.done();
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },

    async updateUser() {
      this.$refs.topProgress.start();

      try {
        
        if ( this.pessoa.telefone.telefone.length > 0 && this.telefone != this.telefone_original ) {
          this.pessoa.telefone.telefone = this.telefone;
          this.pessoa.telefones = [];
          this.pessoa.telefones.push(this.pessoa.telefone);
        }

        this.pessoa.dados_complementares.birthday = this.birthday;

        const logUser = {
            user: {
                email: this.currentPessoa.email.email
            }
        };
        const logContent = this.pessoa;
        logsService.createNewLog(logUser, '', 'Atualizar Usuário', '', logContent);

        await pessoasService.updatePessoa(this.pessoa.id, this.pessoa);

        this.$notify({
          title: 'Usuário atualizado com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.$router.push('/users');
        this.$refs.topProgress.done();
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },

    async redefinirSenha() {
      this.$refs.topProgress.start();
      try {
        console.log(this.pessoa.email.email);
        await authenticationService.updatePassword(this.pessoa.email.email);

        this.$notify({
          title: 'Alteração de senha solicitada!',
          message: 'Enviamos um e-mail para o usuário redefinir sua senha.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
        this.$refs.topProgress.done();
      }
      catch (error) {
        console.log(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    }
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="updateUser()">
        <h5 slot="header" class="title">
          <router-link to="/users" class="fa fa-arrow-left"></router-link>
          Ver Usuário
        </h5>

        <div class="row">
          <div class="col-md-4 pr-md-1">
            <base-input required label="Tipo de usuário*" v-model="tipo_usuario" readonly>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 pr-md-1">
            <base-input
              label="Nome*"
              placeholder="Informe o nome"
              v-model="pessoa.nome"
              required
            ></base-input>
          </div>

          <div class="col-md-4 pr-md-1">
            <base-input
              label="Celular*"
              v-model="telefone"
              placeholder="Informe o melhor celular"
              v-mask="['(##) ####-####', '(##) #####-####']"
              required
            ></base-input>
          </div>

          <div class="col-md-4 pl-md-1">
            <label for="" class="control-label">Data de Nascimento</label><br />
            <birth-datepicker
              v-model="birthday"
              :valueIsString="true"
              :high="true"
              :hideHeader="true"
              :attachment="'bottom'"
              :delimiter="'/'"
              :placeholder="'Informe a data de nascimento. Ex: 27/12/1993'"
              :locale="['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov']"
            />
          </div>
        </div>

        <div class="row">

          <div class="col-md-4 pr-md-3">

            <base-input
              label="CPF*"
              v-model="pessoa.documento.numero"
              placeholder="Informe o seu cpf"
              type="cpf"
              v-mask="['###.###.###-##']"
              required
              disabled
            ></base-input>
          </div>

          <div class="col-md-4 pr-md-3">
            <base-input
              label="E-mail*"
              v-model="pessoa.email.email"
              placeholder="Informe o melhor e-mail"
              type="email"
              disabled
            ></base-input>
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-sm"
        >
          Salvar
        </button>

        <button
          type="button"
          class="btn btn-sm btn-warning"
          @click="redefinirSenha"
        >
          Redefinir senha
        </button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
