import { PessoaCaracteristicas } from '@/store/cwl_enums';
import firebase from 'firebase';
import { currentUser } from '../../firebase';
import { withLog } from '../../logger/with_log';

export default {
  pessoaRef() {
    return firebase.firestore().collection('pessoas');
  },
  async getAllInvestors() {
    return withLog(() => this.pessoaRef().orderBy('email.email', 'asc').get());
  },

  async createNewInvestor(investor) {
    return await withLog(async () => {
      const ref = this.pessoaRef();
      const uid = currentUser().uid;
      console.debug('uid', uid);
      if (!uid) {
        throw 'usuário não foi criado';
      }

      var doc = await ref.doc(uid).get();
      if (!doc.exists) {
        var data = { ...investor, id: uid };
        ref.doc(uid).set(data);
        return data;
      }
    });
  },

  async getInvestor(email) {
    try {
      console.debug('get investor by email', email);
      const snapshot = await this.pessoaRef()
        .where('email.email', '==', email)
        .where('caracteristicas', 'array-contains-any', PessoaCaracteristicas)
        .get();

      return snapshot.empty
        ? undefined
        : { id: snapshot.docs[0].id, ...snapshot.docs[0].data() };
    } catch (error) {
      console.error('get investor by email error', error);
    }
  },

  async get(id) {
    return await withLog(() => this.pessoaRef().doc(id).get());
  },

  async getDetail(id) {
    return await withLog(() => this.pessoaRef().doc(id).get());
  },

  setInvestors(email) {
    withLog(() =>
      this.pessoaRef()
        .where('email.email', '==', email)
        .get()
        .then((response) => {
          response.forEach((doc) => {
            const newInvestor = {
              user: doc.data().dados_complementares.user.email,
              status: doc.data().dados_complementares.status,
            };
          });
        }),
    );
  },

  async updateTermsOfUseAcceptedToFalse() {
    withLog(() =>
      this.pessoaRef()
        .get()
        .then((response) => {
          response.forEach((doc) => {
            const investor = doc.data();
            investor.dados_complementares.termsOfUseAccepted = false;
            withLog(() => this.pessoaRef().doc(doc.id).update(investor));
          });
        }),
    );
  },

  async updateInvestor(id, investor) {
    await withLog(() => this.pessoaRef().doc(id).update(investor));
  },

  // async sendInvestorEmail(pessoa) {
  //   await axios.post('https://us-central1-crowdfunding-wl-dev.cloudfunctions.net/sendMail', {
  //     email: pessoa.dados_complementares.user.email,
  //     name: pessoa.dados_complementares.fullName,
  //     status: pessoa.dados_complementares.approvalStatus,
  //   });
  // },
};
