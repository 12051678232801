<script>
import InvalidDocuments from '@/components/Notifications/Documents/InvalidDocuments';

import settingsService from '@/services/Settings/settingsService';
import attachmentsService from '@/services/Attachments/attachmentsService';

export default {
  data() {
    return {
      documentTypes: [
        'IdentificationDocument',
        'IncomeDocument',
        'AddressDocument',
        'OtherDocument',
      ],
      settings: {},
      getUploadedDocuments: {},
      getFullDocuments: {},
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters.isLoggedIn;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  mounted() {
    this.getUserDocuments();
    this.getSettings();
  },
  methods: {
    translatedDocuments(document) {
      return {
        IdentificationDocument: 'Documento de Identificação',
        IncomeDocument: 'Documento de Renda',
        AddressDocument: 'Documento de Endereço',
        OtherDocument: 'Outro Documento',
        Default: 'Outro Documento',
      }[document ? document : 'Default'];
    },
    reverseTranslatedDocuments(document) {
      return {
        'Documento de Identificação': 'IdentificationDocument',
        'Documento de Renda': 'IncomeDocument',
        'Documento de Endereço': 'AddressDocument',
        'Outro Documento': 'OtherDocument',
      }[document ? document : 'Outro Documento'];
    },
    convertedRoutes(document) {
      return {
        IdentificationDocument: '/documents-list',
        IncomeDocument: '/documents-list/income-document',
        AddressDocument: '/documents-list/address-document',
        OtherDocument: '/documents-list/other-document',
        Default: '/documents-list',
      }[document ? document : 'Default'];
    },
    backProfileValidation() {
      let allDocumentUploaded = null;

      if (
        this.getUploadedDocuments.AddressDocument &&
        this.getUploadedDocuments.IdentificationDocument &&
        this.getUploadedDocuments.IncomeDocument
      ) {
        allDocumentUploaded = true;
      }

      if (this.settings.isMandatoryDocument & !allDocumentUploaded) {
        this.$notify({
          component: InvalidDocuments,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      } else {
        this.$router.push('/profile');
      }
    },
    async getSettings() {
      this.settings = await settingsService.getPublicSettings();
    },
    async getUserDocuments() {
      const result = await attachmentsService.getUserDocuments(
        'InvestorDocuments',
        this.loggedUser.email,
      );

      result.items.map((item) => {
        this.getUploadedDocuments[
          this.reverseTranslatedDocuments(item.name)
        ] = true;
        this.getFullDocuments[this.reverseTranslatedDocuments(item.name)] =
          item;
      });

      console.log(this.getFullDocuments);

      this.$forceUpdate();
    },
    async downloadItem(document) {
      const item = this.getFullDocuments[document];
      try {
        const path = 'InvestorDocuments/' + this.loggedUser.email;
        attachmentsService.downloadAttachment(item.name, path);
      } catch (e) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="documents-list">
    <div class="row">
      <div class="col-12">
        <card>
          <h4 class="card-title">
            <i class="fa fa-arrow-left" @click="backProfileValidation()"></i>
            Lista de Documentos
          </h4>

          <p class="description">
            Os tipos de documentos que devem ser carregados estão abaixo:
          </p>

          <div class="row">
            <div
              class="col-lg-3 col-md-6 col-sm-12 px-3"
              v-for="(document, index) in documentTypes"
              :key="index"
            >
              <label>{{ translatedDocuments(document) }}</label>
              <router-link
                :to="convertedRoutes(document)"
                tag="button"
                class="btn btn-sm"
              >
                <span v-if="getUploadedDocuments[document]">
                  Enviado <i class="fas fa-check"></i>
                </span>
                <span v-if="!getUploadedDocuments[document]"> Selecionar </span>
              </router-link>
              <router-link
                to=""
                class="btn btn-sm"
                @click.native="downloadItem(document)"
                v-if="getFullDocuments[document]"
              >
                Download
              </router-link>
            </div>
          </div>
        </card>
      </div>

      <div class="col-12">
        <card>
          <router-view @uploadDocument="getUserDocuments"></router-view>
        </card>
      </div>
    </div>
  </div>
</template>
