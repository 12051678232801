<script>
import ConfirmationModal from '@/components/ConfirmationModal';
import Vue from 'vue';
import moment from 'moment';
import XLSX from 'xlsx';

import { SlideYUpTransition } from 'vue2-transitions';
import HelperPlugin from '@/plugins/HelperPlugin';

import store from '@/store/index';

Vue.use(HelperPlugin);

export default {
  name: 'contributions-table',
  props: {
    data: {
      type: Array,
      default: () => [],
      description: 'Table data',
    },
    type: {
      type: String,
      default: '',
      description: 'Whether table is striped or hover type',
    },
    theadClasses: {
      type: String,
      default: '',
      description: '<thead> css classes',
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: '<tbody> css classes',
    },
    hasActions: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ConfirmationModal,
    SlideYUpTransition,
  },
  mounted() {
    this.setPages();
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      pages: [],
      showModal: false,
      searchBar: '',
      modalidade: process.env.VUE_APP_TYPE,
      items_exportar: [],
    };
  },

  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    displayedData() {
      this.items_exportar = [];

      return this.data.filter((obj) => {
        this.items_exportar.push({
          id: obj.id,
          nome: obj.pessoa.nome || '',
          email: obj.pessoa.email ? obj.pessoa.email : '',
          telefone: obj.pessoa.telefone ? obj.pessoa.telefone.telefone : '',
          status: obj.status,
          data_transacao: this.formatDate(obj.order_date),
          forma_pagamento: this.formattedPayment(obj.tipo_pagamento),
          valor_transacao: this.totalItem(obj.item),
        });

        return (
          (obj.id ? obj.id : '')
            .toLowerCase()
            .includes(this.searchBar.toLowerCase()) ||
          (obj.order_date ? this.formatDate(obj.order_date) : '')
            .toLowerCase()
            .includes(this.searchBar.toLowerCase()) ||
          (obj.pessoa.nome ? obj.pessoa.nome : '')
            .toLowerCase()
            .includes(this.searchBar.toLowerCase()) ||
          (obj.status ? obj.status : '')
            .toLowerCase()
            .includes(this.searchBar.toLowerCase()) ||
          (obj.tipo_pagamento ? this.formattedPayment(obj.tipo_pagamento) : '')
            .toLowerCase()
            .includes(this.searchBar.toLowerCase()) ||
          (obj.item.value ? obj.item.value : '')
            .toString()
            .includes(this.searchBar)
        );
      });
    },
    totalizer() {
      return this.displayedData.reduce(
        (acc, curr) => acc + this.totalItem(curr.item),
        0,
      );
    },
    totalizerPago() {
      return this.displayedData.reduce(
        (acc, curr) =>
          acc + (curr.status == 'Pago' ? this.totalItem(curr.item) : 0),
        0,
      );
    },
    totalizerPendente() {
      return this.displayedData.reduce(
        (acc, curr) =>
          acc + (curr.status == 'Pendente' ? this.totalItem(curr.item) : 0),
        0,
      );
    },
    totalizerReservado() {
      return this.displayedData.reduce(
        (acc, curr) =>
          acc + (curr.status == 'Reservado' ? this.totalItem(curr.item) : 0),
        0,
      );
    },
    totalizerDevolucaoPendente() {
      return this.displayedData.reduce(
        (acc, curr) =>
          acc +
          (curr.status == 'DevoluçãoPendente' ? this.totalItem(curr.item) : 0),
        0,
      );
    },
    totalizerDevolucaoRealizada() {
      return this.displayedData.reduce(
        (acc, curr) =>
          acc +
          (curr.status == 'DevoluçãoRealizada' ? this.totalItem(curr.item) : 0),
        0,
      );
    },
    tableData() {
      return this.paginate(this.displayedData);
    },
  },
  methods: {
    totalItem(item) {
      return item.value || 0 * item.quantity || 1;
    },
    formatDate(date) {
      return this.$CWLFormatDate(date);
    },

    hasValue(item, column) {
      return item[column.toLowerCase()] !== 'undefined';
    },

    itemValue(item, column) {
      return item[column.toLowerCase()];
    },

    setPages() {
      if (this.pages) {
        this.pages = [];
      }

      const numberOfPages = Math.ceil(this.data.length / this.perPage);

      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    paginate(data) {
      const page = this.page;
      const perPage = this.perPage;

      const from = page * perPage - perPage;
      const to = page * perPage;

      return data.slice(from, to);
    },

    closeModal() {
      this.showPublicarModal = false;
      this.showEnviarParaAnaliseModal = false;
    },
    formattedPayment(payment) {
      const paymentWays = {
        CartaoCredito: 'Cartão de crédito',
        Boleto: 'Boleto',
        Pix: 'Pix',
        Default: 'TED',
      };

      return paymentWays[payment] || paymentWays['Default'];
    },

    async downloadUsuarios() {
      const data = XLSX.utils.json_to_sheet(this.items_exportar);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'data');
      XLSX.writeFile(wb, 'dados_usuario.csv');
    },
  },
  watch: {
    displayedData() {
      this.setPages();
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label
            class="control-label"
            v-tooltip="
              'Você pode pesquisar digitando no campo de busca abaixo. A pesquisa é realizada por qualquer informação que está na tela.'
            "
            style="cursor: default"
            >Pesquisar</label
          >
          <input
            type="text"
            class="form-control"
            placeholder="Para pesquisar, digite sua busca aqui..."
            v-model="searchBar"
          />
        </div>
      </div>
    </div>

    <table class="table table-hover" :class="tableClass">
      <thead class="thead-light">
        <tr>
          <slot name="columns">
            <!-- <th>ID</th> -->
            <th>Data da Transação</th>
            <th>Nome de quem pagou</th>
            <th>Status de Pagamento</th>
            <th>Forma de Pagamento</th>
            <th>Valor da Transação</th>
          </slot>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in tableData" :key="index">
          <!-- <td>
            {{ item.id }}
          </td> -->
          <td>
            {{ item.order_date | moment('LLL') }}
          </td>
          <td>
            {{ item.pessoa.nome }}
          </td>
          <td>
            {{ item.status }}
          </td>
          <td>
            {{ formattedPayment(item.tipo_pagamento) }}
          </td>
          <td>
            {{ totalItem(item.item) | currency }}
          </td>
        </tr>
      </tbody>
    </table>

    <div class="no-data" v-if="!tableData.length && modalidade == 'doacao'">
      Não há aportes realizados nesta campanha ainda :(
    </div>

    <div class="no-data" v-if="!tableData.length && modalidade == 'equity'">
      Não há aportes realizados nesta oferta ainda :(
    </div>

    <div class="totalizer" v-if="totalizerPago">
      Total Pago: {{ totalizerPago | currency }}
    </div>

    <div class="totalizer" v-if="totalizerPendente">
      Total Pendente: {{ totalizerPendente | currency }}
    </div>

    <!-- <div class="totalizer" v-if="modalidade = 'equity'">
      Total Reservado: {{ totalizerReservado | currency }}
    </div> -->

    <div class="totalizer">
      Total Devolução Pendente: {{ totalizerDevolucaoPendente | currency }}
    </div>

    <div class="totalizer">
      Total Devolução Realizada: {{ totalizerDevolucaoRealizada | currency }}
    </div>

    <div class="totalizer">Total: {{ totalizer | currency }}</div>

    <div class="row">
      <button
        type="button"
        class="btn btn-sm"
        style="padding: 10px"
        @click="downloadUsuarios"
      >
        Dados de Usuários
      </button>
    </div>

    <nav class="table-pagination">
      <ul class="pagination">
        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page != 1"
            @click="page--"
          >
            <i class="fas fa-angle-left"></i>
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
            :key="index"
            @click="page = pageNumber"
            :class="{ 'is-active': page === pageNumber }"
          >
            {{ pageNumber }}
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page < pages.length"
            @click="page++"
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </li>
      </ul>
    </nav>

    <confirmation-modal
      :show="showModal"
      @close="showModal = false"
      @confirmDelete="deleteItem()"
    ></confirmation-modal>
  </div>
</template>

<style
  lang="scss"
  scoped
  src="@/assets/sass/black-dashboard/views/_contributions.scss"
></style>
