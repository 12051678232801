<script>
import GetCampaignErrorNotification from '@/components/Notifications/Campaign/GetCampaignErrorNotification';
import GetFinancialErrorNotification from '@/components/Notifications/Campaign/GetFinancialErrorNotification';
import FinancialTable from '@/components/Campaign/FinancialTable';

import financingService from '@/services/Financing/financingService';
import ordersService from '@/services/Orders/ordersService';

const tableColumns = ['Valor do Investimento', 'Forma de Pagamento', 'Data de Compra'];

export default {
  name: 'campaign-details',
  components: {
    FinancialTable,
  },
  data() {
    return {
      campaign: {},
      financials: [],
      isLoading: true,
      table: {
        columns: [...tableColumns],
      },
      modalidade: process.env.VUE_APP_TYPE,
    };
  },
  computed: {
    campaignId() {
      return this.$route.params.id;
    },
    envPath() {
      return process.env.VUE_APP_ROOT_SITE;
    },
    loggedUser() {
      return this.$store.getters.isLoggedIn;
    },
    loggedPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  mounted() {
    this.getCampaignDetails();
  },
  methods: {
    async getCampaignDetails() {
      try {
        const campaign = await financingService.get(this.campaignId);

        this.campaign = campaign.data();

        document.getElementById(
            'parsedContent',
        ).innerHTML = await campaign.data().content;

        this.getFinancialDetails();
      } catch (e) {
        this.$notify({
          component: GetCampaignErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    async getFinancialDetails() {
      try {
        this.financials = await ordersService.getOrderByUserAndId(this.loggedPessoa.pessoa_id, this.campaignId);
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        this.$notify({
          component: GetFinancialErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    redirectToSite() {
      location.replace(`${this.envPath}project-detail/${this.campaignId}`);
    },
  },
};
</script>

<template>
  <div class="row campaign-details">
    <div class="col-md-12">
      <card>
        <div class="card-header">
          <h3 class="card-title" v-if="modalidade == 'doacao'">
            <router-link to="/dashboard" class="fas fa-arrow-left" tag="i" />
            Detalhes da Campanha
          </h3>
          <h3 class="card-title" v-if="modalidade == 'equity'">
            <router-link to="/dashboard" class="fas fa-arrow-left" tag="i" />
            Detalhes da Oferta
          </h3>

          <!-- <button class="btn" @click="redirectToSite()">
            Visualizar no Site
          </button> -->
        </div>

        <div class="campaign-informations">
          <div id="parsedContent"></div>
        </div>
      </card>

      <card v-if="modalidade == 'equity'">
        <div class="card-header">
          <h3 class="card-title">{{ $t('financing.relevantInformantionsTitle') }}</h3>
        </div>
        <div class="card-body">
          {{ $t('financing.relevantInformantionsBody') }} <a target="_blank" :href="this.campaign.informacoes_relevantes ? this.campaign.informacoes_relevantes : ''">{{ $t('financing.relevantInformantionsClickHere') }}</a>.
        </div>
      </card>

      <card>
        <div class="card-header">
          <h3 class="card-title" v-if="modalidade == 'doacao'">Investimentos nesta Campanha</h3>
          <h3 class="card-title" v-if="modalidade == 'equity'">Investimentos nesta Oferta</h3>
        </div>

        <div class="financial-details" v-if="!isLoading">
          <financial-table
            :data="financials"
            :columns="table.columns"
            thead-classes="text-primary" />
        </div>
      </card>
    </div>
  </div>
</template>
