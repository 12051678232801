<script>
import { cep } from '@/validate/customValidators.js';
import pessoasService from '@/services/Pessoas/pessoasService';
import statesService from '@/services/States/statesService';

export default {
  data() {
    return {
      model: {
        states: [],
      },
      pessoa: {
        endereco: {},
        dados_complementares: {
          dados_bancarios: {},
          perfil: {},
        },
      },
      isLoadingEnderecoPessoa: false,
      limite_investimento: '',
      stateSelected: undefined,
    };
  },
  validations: {
    pessoa: {
      endereco: {
        cep: {
          valid: cep,
        },
      },
    },
  },
  async mounted() {
    await this.getStates();
    this.pessoa = this.$attrs.pessoa || this.pessoa;
    this.setAddressStateSelected();

    this.model = this.$attrs.model || this.model;
    this.updateLimiteInvestimento();
  },
  computed: {
    investor() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    saveProfileInformations() {
      const pessoa = { ...this.pessoa };
      pessoa.endereco.estado = this.stateSelected;
      this.$emit('saveProfileInformations', { pessoa: pessoa });
    },
    setAddressStateSelected() {
      this.stateSelected = undefined;

      if (!this.pessoa.endereco) return;

      const estado = this.pessoa.endereco.estado;

      if (typeof estado == 'string') {
        for (let index = 0; index < this.model.states.length; index++) {
          const _state = this.model.states[index];
          if (_state.uf == estado) {
            this.stateSelected = _state;
            break;
          }
        }
      } else if (estado.uf) {
        this.stateSelected = estado;
      }
    },
    async getStates() {
      this.model.states = await statesService.getAllStates();
    },
    async changeCEP() {
      if (!this.$v.pessoa.endereco.cep.valid || this.isLoadingEnderecoPessoa)
        return;

      if (!this.isLoadingEnderecoPessoa) {
        this.isLoadingEnderecoPessoa = true;
        try {
          const enderecoFound = await pessoasService.searchAddressByZipcode(
            this.pessoa.endereco.cep,
          );
          this.pessoa.endereco = Object.assign(
            this.pessoa.endereco || {},
            enderecoFound || {},
          );
          this.setAddressStateSelected();
        } catch (error) {
          console.error(error);
        }
        this.isLoadingEnderecoPessoa = false;
      }
    },
    /* eslint-disable max-len */
    updateLimiteInvestimento() {
      const rendaBruta = this.pessoa.dados_complementares.perfil
        .renda_bruta_anual
        ? this.pessoa.dados_complementares.perfil.renda_bruta_anual
        : 0;
      const investimentosFinanceiros = this.pessoa.dados_complementares.perfil
        .investimentos_financeiros
        ? this.pessoa.dados_complementares.perfil.investimentos_financeiros
        : 0;
      const investimentosNestaPlataforma = this.pessoa.dados_complementares
        .perfil.investimentos_nesta_plataforma
        ? this.pessoa.dados_complementares.perfil.investimentos_nesta_plataforma
        : 0;
      const investimentosOutrasPlataformas = this.pessoa.dados_complementares
        .perfil.investimentos_outras_plataformas
        ? this.pessoa.dados_complementares.perfil
            .investimentos_outras_plataformas
        : 0;

      if (investimentosFinanceiros > 1000000) {
        this.limite_investimento = 'Ilimitado';
      } else if (investimentosFinanceiros > 100000 || rendaBruta > 100000) {
        let calculo10Porcento = 0;
        if (investimentosFinanceiros > rendaBruta) {
          calculo10Porcento = investimentosFinanceiros * 0.1;
        } else {
          calculo10Porcento = rendaBruta * 0.1;
        }
        const montante =
          investimentosNestaPlataforma + investimentosOutrasPlataformas;
        const limiteInvestimentoPuro = calculo10Porcento - montante;

        const limiteInvestimentoPreFormatado = parseFloat(
          limiteInvestimentoPuro,
        )
          .toFixed(2)
          .replace('.', ',');
        const limiteInvestimento =
          'R$ ' +
          limiteInvestimentoPreFormatado
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        this.limite_investimento = limiteInvestimento;
      } else {
        const montante =
          investimentosNestaPlataforma + investimentosOutrasPlataformas;
        const limiteInvestimentoPuro = 10000 - montante;

        const limiteInvestimentoPreFormatado = parseFloat(
          limiteInvestimentoPuro,
        )
          .toFixed(2)
          .replace('.', ',');
        const limiteInvestimento =
          'R$ ' +
          limiteInvestimentoPreFormatado
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        this.limite_investimento = limiteInvestimento;
      }
    },
  },
};
</script>

<template>
  <card>
    <form @submit.prevent="saveProfileInformations()">
      <h5 slot="header" class="title mt-3">Dados Complementares</h5>
      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            v-model="pessoa.dados_complementares.rg"
            label="RG"
            placeholder="Informe seu RG"
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input label="Estado Civil">
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.estado_civil"
            >
              <option value="">Selecionar</option>
              <option value="solteiro">Solteiro(a)</option>
              <option value="casado">Casado(a)</option>
              <option value="divorciado">Divorciado(a)</option>
              <option value="viuvo">Viúvo(a)</option>
              <option value="separado_judicialmente">
                Separado(a) Judicialmente
              </option>
              <option value="outro">Outro</option>
            </select>
          </base-input>
        </div>
        <div class="col-md-4 pl-md-1">
          <base-input label="Genêro">
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.genero"
            >
              <option value="">Selecionar</option>
              <option value="masculino">Masculino</option>
              <option value="feminino">Feminino</option>
              <option value="outro">Outro</option>
            </select>
          </base-input>
        </div>
      </div>

      <h5 class="title mt-3">Endereço</h5>
      <div class="row">
        <div class="col-md-12 pr-md-1">
          <div class="form-group">
            <base-input
              label="CEP"
              v-mask="'#####-###'"
              placeholder="Digite seu CEP"
              v-model="pessoa.endereco.cep"
              v-on:input="changeCEP"
            ></base-input>
            <label v-if="isLoadingEnderecoPessoa" style="cursor: default"
              ><i>Pesquisando...</i></label
            >
            <div
              class="error"
              v-if="pessoa.endereco.cep && !$v.pessoa.endereco.cep.valid"
            >
              Informe um CPF válido.
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Número"
            placeholder="Digite seu Número"
            v-mask="['########']"
            v-model="pessoa.endereco.numero"
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Complemento"
            placeholder="Digite seu Complemento"
            v-model="pessoa.endereco.complemento"
          ></base-input>
        </div>
        <div class="col-md-4 pl-md-1">
          <base-input
            label="Bairro"
            v-model="pessoa.endereco.bairro"
            placeholder="Digite seu Bairro"
          ></base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Rua"
            v-model="pessoa.endereco.rua"
            placeholder="Digite sua Rua"
          >
          </base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Cidade"
            placeholder="Digite sua Cidade"
            v-model="pessoa.endereco.cidade"
          ></base-input>
        </div>
        <div class="col-md-4 pl-md-1">
          <label>Estado</label>
          <select class="form-control" v-model="stateSelected" required>
            <option
              :value="state"
              v-for="(state, index) in model.states"
              :key="index"
            >
              {{ state.name }}
            </option>
          </select>
        </div>
      </div>

      <h5 class="title mt-3">Profissão</h5>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Ocupação"
            placeholder="Digite sua Ocupação"
            v-model="pessoa.dados_complementares.ocupacao"
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Nome da Empresa"
            placeholder="Digite o nome de sua Empresa"
            v-model="pessoa.dados_complementares.nome_empresa"
          ></base-input>
        </div>
        <div class="col-md-4 pl-md-1">
          <base-input
            label="Cargo"
            placeholder="Digite seu Cargo"
            v-model="pessoa.dados_complementares.cargo"
          ></base-input>
        </div>
      </div>

      <h5 class="title mt-3">Dados Bancários</h5>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Agência sem dígito"
            placeholder="Digite sua Agência"
            v-model="pessoa.dados_complementares.dados_bancarios.agencia"
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Conta com dígito"
            placeholder="Digite sua Conta"
            v-model="pessoa.dados_complementares.dados_bancarios.conta"
          ></base-input>
        </div>
        <div class="col-md-4 pl-md-1">
          <base-input label="Banco" required>
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.dados_bancarios.banco"
            >
              <option value="" disabled>Selecionar</option>
              <option
                v-for="bank in model.banks"
                :key="bank.code"
                :value="bank.name"
              >
                {{ bank.name }}
              </option>
            </select>
          </base-input>
        </div>
      </div>

      <h5 class="title mt-3">Perfil de Investimento</h5>

      <div class="row">
        <div class="col-md-12">
          <base-input
            required
            label="Qual situação melhor se encaixa ao seu perfil atual em relação a investimentos? (opcional)"
          >
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.perfil.perfil"
            >
              <option value="" disabled>Selecionar</option>
              <option
                v-for="profile in model.profiles"
                :key="profile.id"
                :value="profile.profile"
              >
                {{ profile.profile }}
              </option>
            </select>
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input
            label="Qual o principal segmento você tem interesse em investir? (opcional)"
          >
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.perfil.segmento"
            >
              <option value="" disabled>Selecionar</option>
              <option
                v-for="segment in model.segments"
                :key="segment.id"
                :value="segment.name"
              >
                {{ segment.name }}
              </option>
            </select>
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input
            label="Quanto do seu patrimônio você está disposto a investir? (opcional)"
          >
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.perfil.valor"
            >
              <option value="" disabled>Selecionar</option>
              <option
                v-for="value in model.values"
                :key="value.id"
                :value="value.values"
              >
                {{ value.values }}
              </option>
            </select>
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 mb-1">
          <base-checkbox
            v-model="pessoa.dados_complementares.aparecer_investidor"
          >
            Concordo em aparecer como investidor
          </base-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 mb-3">
          <base-checkbox
            v-model="pessoa.dados_complementares.politicamente_exposto"
          >
            Sou uma pessoa politicamente exposta
          </base-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label for="other-investments"
            >Outros investimentos através de plataformas no ano atual</label
          >
          <currency-input
            id="other-investments"
            class="form-control"
            v-model="
              pessoa.dados_complementares.perfil
                .investimentos_outras_plataformas
            "
            placeholder="Informe o valor dos Investimentos"
            currency="BRL"
            locale="pt-br"
            :auto-decimal-mode="true"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label for="investment-this-platform"
            >Total Investido no ano nesta plataforma</label
          >
          <currency-input
            readonly
            id="investment-this-platform"
            class="form-control"
            v-model="
              pessoa.dados_complementares.perfil.investimentos_nesta_plataforma
            "
            placeholder="R$ 0,00"
            currency="BRL"
            locale="pt-br"
            :auto-decimal-mode="true"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label for="annual-gross">Sua Renda Bruta anual</label>
          <currency-input
            id="annual-gross"
            class="form-control"
            v-model="pessoa.dados_complementares.perfil.renda_bruta_anual"
            placeholder="Informe o valor de sua Renda Bruta Anual"
            currency="BRL"
            locale="pt-br"
            :auto-decimal-mode="true"
            v-on:focusout="updateLimiteInvestimento"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label for="financial-investments"
            >Informe o valor de seus Investimentos Financeiros</label
          >
          <currency-input
            id="financial-investments"
            class="form-control"
            v-model="
              pessoa.dados_complementares.perfil.investimentos_financeiros
            "
            placeholder="Informe o valor de seus Investimentos Financeiros"
            currency="BRL"
            locale="pt-br"
            :auto-decimal-mode="true"
            v-on:focusout="updateLimiteInvestimento"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input
            label="Limite Atual de Investimento"
            placeholder=""
            v-model="limite_investimento"
            readonly
          ></base-input>
        </div>
      </div>

      <button
        type="submit"
        slot="footer"
        class="btn btn-sm btn-primary mt-3"
        fill
      >
        Salvar
      </button>

      <router-link
        to="documents-list"
        tag="button"
        class="btn btn-sm btn-primary mt-3"
      >
        Meus Documentos
      </router-link>
    </form>
  </card>
</template>
