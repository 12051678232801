var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-user"},[_c('card',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.addNewPessoa()}}},[_c('h5',{staticClass:"title",attrs:{"slot":"header"},slot:"header"},[_c('router-link',{staticClass:"fa fa-arrow-left",attrs:{"to":"/users"}}),_vm._v(" Criar Usuário ")],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 pr-md-1"},[_c('base-input',{attrs:{"required":"","label":"Tipo de usuário*"}},[_c('v-select',{staticClass:"style-chooser",attrs:{"options":_vm.tipos_usuario,"components":{ Deselect: _vm.Deselect }},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.tipo_usuario}},'input',attributes,false),events))]}}]),model:{value:(_vm.tipo_usuario),callback:function ($$v) {_vm.tipo_usuario=$$v},expression:"tipo_usuario"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 pr-md-1"},[_c('base-input',{attrs:{"label":"Nome*","placeholder":"Informe o nome","required":""},model:{value:(_vm.pessoa.nome),callback:function ($$v) {_vm.$set(_vm.pessoa, "nome", $$v)},expression:"pessoa.nome"}})],1),_c('div',{staticClass:"col-md-4 pr-md-1"},[_c('div',{staticClass:"form-group"},[_c('base-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) ####-####', '(##) #####-####']),expression:"['(##) ####-####', '(##) #####-####']"}],attrs:{"label":"Celular*","placeholder":"Informe o melhor celular","required":""},model:{value:(_vm.pessoa.telefone.telefone),callback:function ($$v) {_vm.$set(_vm.pessoa.telefone, "telefone", $$v)},expression:"pessoa.telefone.telefone"}}),(
                _vm.pessoa.telefone.telefone != '' &&
                !_vm.$v.pessoa.telefone.telefone.valid
              )?_c('div',{staticClass:"error"},[_vm._v(" Informe um telefone com DDD válido. ")]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4 pl-md-1"},[_c('label',{staticClass:"control-label",attrs:{"for":""}},[_vm._v("Data de Nascimento")]),_c('br'),_c('birth-datepicker',{attrs:{"valueIsString":true,"high":true,"hideHeader":true,"attachment":'bottom',"delimiter":'/',"placeholder":'Informe a data de nascimento. Ex: 27/12/1993',"locale":[
              'Jan',
              'Fev',
              'Mar',
              'Abr',
              'Mai',
              'Jun',
              'Jul',
              'Ago',
              'Set',
              'Out',
              'Nov' ]},model:{value:(_vm.birthday),callback:function ($$v) {_vm.birthday=$$v},expression:"birthday"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 pr-md-3"},[_c('div',{staticClass:"form-group"},[_c('base-input',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##']),expression:"['###.###.###-##']"}],attrs:{"label":"CPF*","placeholder":"Informe o seu cpf","type":"cpf","required":""},model:{value:(_vm.pessoa.documento.numero),callback:function ($$v) {_vm.$set(_vm.pessoa.documento, "numero", $$v)},expression:"pessoa.documento.numero"}}),(
                _vm.pessoa.documento.numero != '' &&
                !_vm.$v.pessoa.documento.numero.valid
              )?_c('div',{staticClass:"error"},[_vm._v(" Informe um CPF válido. ")]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4 pr-md-3"},[_c('div',{staticClass:"form-group"},[_c('base-input',{attrs:{"label":"E-mail*","placeholder":"Informe o melhor e-mail","type":"email","required":""},model:{value:(_vm.pessoa.email.email),callback:function ($$v) {_vm.$set(_vm.pessoa.email, "email", $$v)},expression:"pessoa.email.email"}}),(_vm.pessoa.email.email != '' && !_vm.$v.pessoa.email.email.valid)?_c('div',{staticClass:"error"},[_vm._v(" Informe um e-mail válido. ")]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4 pr-md-1"},[_c('label',{staticClass:"control-label",attrs:{"for":""}},[_vm._v("Senha (mínimo 6 caracteres)*")]),_c('password',{attrs:{"secureLength":6,"toggle":true,"defaultClass":'form-control',"required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]),_c('button',{staticClass:"btn btn-sm",attrs:{"type":"submit"}},[_vm._v("Adicionar")])])]),_c('vue-topprogress',{ref:"topProgress"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }