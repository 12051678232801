<script>
import EditProfileForm from './Profile/EditProfileForm';

import statesService from '@/services/States/statesService';
import banksService from '@/services/Banks/banksService';
import segmentsService from '@/services/Segments/segmentsService';
import valuesService from '@/services/Values/valuesService';
import investmentsProfile from '@/services/InvestmentsProfile/investmentsProfileService';
import investorsService from '@/services/Investors/investorsService';
import settingsService from '@/services/Settings/settingsService';
import attachmentsService from '@/services/Attachments/attachmentsService';

import GetInvestorErrorNotification from '@/components/Notifications/Investors/GetInvestorErrorNotification';
import UpdateInvestorErrorNotification from '@/components/Notifications/Investors/UpdateInvestorErrorNotification';
import UpdateInvestorSuccessNotification from '@/components/Notifications/Investors/UpdateInvestorSuccessNotification';
import EmptyProfileNamePersonType from '@/components/Notifications/Investors/EmptyProfileNamePersonType';
import InvalidCPFNotification from '@/components/Notifications/Investors/InvalidCPFNotification';

import Vue from 'vue';
import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

import pessoasService from '@/services/Pessoas/pessoasService';
import authenticationService from '@/services/Authentication/authenticationService';

import { vueTopprogress } from 'vue-top-progress';

import birthDatepicker from 'vue-birth-datepicker';

import { email, required } from 'vuelidate/lib/validators';
import { phone, cpf } from '@/validate/customValidators.js';

export default {
  components: {
    EditProfileForm,
    vueTopprogress,
    birthDatepicker,
  },
  data() {
    return {
      model: {
        states: [],
        banks: [],
        segments: [],
        values: [],
        profiles: [],
      },
      pessoa: {
        endereco: {},
        dados_bancarios: {},
        dados_complementares: {
          endereco: {
            countryname: 'Brasil',
          },
          user: {},
          profile: {},
          bank: {},
          dados_bancarios: {},
          perfil: {},
        },
      },
      operationalInformations: [],
      settings: {},
      getUploadedDocuments: {},
      nome: '',
      telefone: '',
      birthday: '',
      modalidade: process.env.VUE_APP_TYPE,
    };
  },
  validations: {
    telefone: {
      valid: (telefone) => required(telefone) && phone(telefone),
    },
  },
  async mounted() {
    this.$refs.topProgress.start();
    try {
      this.nome = this.currentPessoa.nome;
      this.telefone = this.currentPessoa.telefone
        ? this.currentPessoa.telefone.telefone
        : '';
      this.birthday = this.currentPessoa.dados_complementares
        ? this.currentPessoa.dados_complementares.birthday
        : '';

      await this.getInvestorInformations();
      this.getStates();
      this.getBanks();
      this.getSegments();
      this.getValues();
      this.getProfiles();

      this.getSettings();
      this.getUserDocuments();
    } catch (error) {
      console.error(error);
    }
    this.$refs.topProgress.done();
  },
  computed: {
    isValidEmail() {
      const value = this.currentPessoa.email.email;
      return value != '' && email(value);
    },
    isValidCpf() {
      const value = this.currentPessoa.documento.numero;
      return value != '' && cpf(value);
    },
    userRole() {
      const Claim = this.$store.getters.userClaim;

      return Claim.role;
    },
    loggedUser() {
      return this.$store.getters.isLoggedIn;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  methods: {
    async getStates() {
      this.model.states = await statesService.getAllStates();
    },
    async getBanks() {
      this.model.banks = await banksService.getAllBanks();
    },
    async getValues() {
      const values = await valuesService.getAllValues();

      values.forEach((value) => {
        const newValue = value.data();
        newValue.id = value.id;

        this.model.values.push(newValue);
      });
    },
    async getSegments() {
      this.model.segments = await segmentsService.getAllSegments();
    },
    async getProfiles() {
      const profiles = await investmentsProfile.getAllInvestments();

      profiles.forEach((profile) => {
        const newProfile = profile.data();
        newProfile.id = profile.id;

        this.model.profiles.push(newProfile);
      });
    },
    async getInvestorInformations() {
      try {
        const pessoa = await pessoasService.findCurrentUser();

        if (!pessoa) throw 'pessoa not-found';

        pessoa.dados_bancarios = pessoa.dados_bancarios || {};
        pessoa.dados_complementares = pessoa.dados_complementares || {};
        pessoa.dados_complementares.perfil =
          pessoa.dados_complementares.perfil || {};
        pessoa.dados_complementares.dados_bancarios =
          pessoa.dados_complementares.dados_bancarios || {};
        pessoa.endereco = pessoa.endereco || {};
        pessoa.telefone = pessoa.telefone || {};
        pessoa.telefones = pessoa.telefones || [];

        this.pessoa = pessoa;

        // const userInformations = await investorsService.getInvestor(this.loggedUser.email);
      } catch (e) {
        console.error('get investor information error', error);
        this.$notify({
          component: GetInvestorErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    async saveProfileInformations() {
      if (!this.validarCPF(this.pessoa.dados_complementares.user.cpf)) {
        this.$notify({
          component: InvalidCPFNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
        return;
      }

      try {
        if (
          this.pessoa.dados_complementares.fullName &&
          this.pessoa.dados_complementares.perfil.personType
        ) {
          let allDocumentUploaded = null;

          if (
            this.getUploadedDocuments.AddressDocument &&
            this.getUploadedDocuments.IdentificationDocument &&
            this.getUploadedDocuments.IncomeDocument
          ) {
            allDocumentUploaded = true;
          }

          if (
            (this.settings.isMandatoryDocument && allDocumentUploaded) ||
            !this.settings.isMandatoryDocument
          ) {
            this.pessoa.dados_complementares.status = 'completed-register';
          }

          await investorsService.updateInvestor(this.pessoa.id, this.pessoa);

          this.getInvestorInformations();

          this.$notify({
            component: UpdateInvestorSuccessNotification,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 3000,
          });
        } else {
          this.$notify({
            component: EmptyProfileNamePersonType,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
        }
      } catch (e) {
        this.$notify({
          component: UpdateInvestorErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    async getSettings() {
      this.settings = await settingsService.getPublicSettings();
    },
    async getUserDocuments() {
      const result = await attachmentsService.getUserDocuments(
        'InvestorDocuments',
        this.loggedUser.email,
      );

      result.items.map((item) => {
        this.getUploadedDocuments[
          this.reverseTranslatedDocuments(item.name)
        ] = true;
      });

      this.$forceUpdate();
    },
    reverseTranslatedDocuments(document) {
      return {
        'Documento de Identificação': 'IdentificationDocument',
        'Documento de Renda': 'IncomeDocument',
        'Documento de Endereço': 'AddressDocument',
        'Outro Documento': 'OtherDocument',
      }[document ? document : 'Outro Documento'];
    },
    validarCPF(c) {
      const cpf = c.replace(/[^\d]+/g, '');
      if (cpf == '') return false;
      // Elimina CPFs invalidos conhecidos
      if (
        cpf.length != 11 ||
        cpf == '00000000000' ||
        cpf == '11111111111' ||
        cpf == '22222222222' ||
        cpf == '33333333333' ||
        cpf == '44444444444' ||
        cpf == '55555555555' ||
        cpf == '66666666666' ||
        cpf == '77777777777' ||
        cpf == '88888888888' ||
        cpf == '99999999999'
      ) {
        return false;
      }
      // Valida 1o digito
      let add = 0;
      for (let i = 0; i < 9; i++) {
        add += parseInt(cpf.charAt(i)) * (10 - i);
      }
      let rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) {
        rev = 0;
      }
      if (rev != parseInt(cpf.charAt(9))) {
        return false;
      }
      // Valida 2o digito
      add = 0;
      for (let i = 0; i < 10; i++) {
        add += parseInt(cpf.charAt(i)) * (11 - i);
      }
      rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) {
        rev = 0;
      }
      if (rev != parseInt(cpf.charAt(10))) {
        return false;
      }
      return true;
    },

    async saveProfileInformations(params) {
      const pessoa =
        params && params.pessoa
          ? { ...this.pessoa, ...params.pessoa }
          : this.pessoa;
      this.updateUser(pessoa);
    },

    async updateUser(pessoaP = null) {
      if (this.$v.$invalid) {
        return this.$notify({
          title: 'Informe os dados corretamente!',
          message:
            'Um ou mais dados informados não foram preenchidos corretamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 7000,
        });
      }

      this.$refs.topProgress.start();

      try {
        const pessoa = pessoaP || this.pessoa;
        if (this.telefone.length > 0) {
          pessoa.telefone.telefone = this.telefone;
          pessoa.telefones = [pessoa.telefone];
          // pessoa.telefones.push(pessoa.telefone);
        }

        if (this.currentPessoa.documento) {
          pessoa.documento.numero = this.currentPessoa.documento.numero;
          pessoa.documentos = [pessoa.documento];
          // pessoa.documentos.push(pessoa.documento);
        }

        pessoa.nome = this.nome;
        pessoa.dados_complementares.birthday = this.birthday;

        await pessoasService.updatePessoaBy(pessoa, {
          email: pessoa.email.email,
        });

        const objCooke = this.$CWLCreatePessoaForCookie(pessoa);
        this.$store.commit('SET_PESSOA', objCooke);

        this.$notify({
          title: 'Dados atualizados com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      } catch (error) {
        console.log(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },

    async redefinirSenha() {
      this.$refs.topProgress.start();
      try {
        await authenticationService.updatePassword(
          this.currentPessoa.email.email,
        );

        this.$notify({
          title: 'Alteração de senha solicitada!',
          message: 'Enviamos um e-mail pra você redefinir sua senha.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
        this.$refs.topProgress.done();
      } catch (error) {
        console.log(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },
  },
};
</script>
<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="updateUser()">
        <h5 slot="header" class="title">Meus Dados</h5>

        <div class="row">
          <div class="col-md-4 pr-md-1">
            <base-input
              label="Nome*"
              placeholder="Informe o nome"
              v-model="nome"
              required
            ></base-input>
          </div>

          <div class="col-md-4 pr-md-1">
            <div class="form-group">
              <base-input
                label="Celular*"
                v-model="telefone"
                placeholder="Informe o melhor celular"
                v-mask="['(##) ####-####', '(##) #####-####']"
                required
              ></base-input>
              <div class="error" v-if="!$v.telefone.valid">
                Informe um telefone com DDD válido.
              </div>
            </div>
          </div>

          <div class="col-md-4 pl-md-1">
            <label for="" class="control-label">Data de Nascimento</label><br />
            <birth-datepicker
              v-model="birthday"
              :valueIsString="true"
              :high="true"
              :hideHeader="true"
              :attachment="'bottom'"
              :delimiter="'/'"
              :placeholder="'Informe a data de nascimento. Ex: 27/12/1993'"
              :locale="[
                'Jan',
                'Fev',
                'Mar',
                'Abr',
                'Mai',
                'Jun',
                'Jul',
                'Ago',
                'Set',
                'Out',
                'Nov',
              ]"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 pr-md-3">
            <div class="form-group">
              <base-input
                label="E-mail*"
                v-model="currentPessoa.email.email"
                placeholder="Informe o melhor e-mail"
                type="email"
                disabled
              ></base-input>
              <div class="error" v-if="!isValidEmail">
                Informe um e-mail válido.
              </div>
            </div>
          </div>
          <div class="col-md-4 pr-md-3">
            <div class="form-group">
              <base-input
                label="CPF*"
                v-model="currentPessoa.documento.numero"
                placeholder="Informe o seu cpf"
                type="cpf"
                v-if="userRole != 'master'"
                v-mask="['###.###.###-##']"
                disabled
              ></base-input>
              <base-input
                label="CPF*"
                v-model="currentPessoa.documento.numero"
                placeholder="Informe o seu cpf"
                type="cpf"
                v-if="userRole == 'master'"
                v-mask="['###.###.###-##']"
              ></base-input>
              <div class="error" v-if="!isValidCpf">Informe um CPF válido.</div>
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-sm">Salvar</button>

        <button
          type="button"
          class="btn btn-sm btn-warning"
          @click="redefinirSenha"
        >
          Redefinir senha
        </button>
      </form>
    </card>

    <div
      class="row profile"
      v-if="
        modalidade == 'equity' &&
        userRole !== 'master' &&
        pessoa.email != undefined
      "
    >
      <div class="col-md-12">
        <edit-profile-form
          :pessoa="pessoa"
          :model="model"
          @saveProfileInformations="saveProfileInformations"
        />
      </div>

      <!-- <div class="col-md-12">
        <user-card
          @loadDocument="loadDocument()"
          :pessoa="pessoa"
          :model="model"
          v-if="userRole !== 'master' && userRole !== 'operational'"
        />
      </div> -->
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
