import Axios from 'axios';

/**
 * financingId: financing id to send approved email
 */
export default async (financingId) => {
  const endpoint = process.env.VUE_APP_ENDPOINT_WS + 'sendEmailFinancingApproved';
  try {
    return await Axios.post(endpoint, { financingId }).then(r => r.data);
  } catch (error) {
    throw new Error(error.message);
  }
};
