<script>
import ConfirmationModal from '@/components/ConfirmationModal';

export default {
  name: 'investors-table',
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: 'Table columns',
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Table data',
    },
    type: {
      type: String,
      default: '',
      description: 'Whether table is striped or hover type',
    },
    theadClasses: {
      type: String,
      default: '',
      description: '<thead> css classes',
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: '<tbody> css classes',
    },
    hasActions: {
      type: Boolean,
      default: false,
    },
    isDownloadable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      pages: [],
      showModal: false,
      preDeletedItem: {},
      searchBar: ''
    };
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    displayedData() {
      return this.paginate(this.filteredData);
    },
    filteredData() {
      return this.data.filter(obj => {
        return(
             (obj.dados_complementares.fullName ? obj.dados_complementares.fullName : '').toLowerCase().includes(this.searchBar.toLowerCase())
          || (obj.dados_complementares.user.email ? obj.dados_complementares.user.email : '').toLowerCase().includes(this.searchBar.toLowerCase())
          || (obj.dados_complementares.user.phone ? obj.dados_complementares.user.phone : '').toLowerCase().includes(this.searchBar.toLowerCase())
          || (obj.dados_complementares.approvalStatus ? obj.dados_complementares.approvalStatus : '').toLowerCase().includes(this.searchBar.toLowerCase())
        )
      });
    },
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== 'undefined';
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    setPages() {
      if (this.pages) {
        this.pages = [];
      }

      const numberOfPages = Math.ceil(this.data.length / this.perPage);

      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      const page = this.page;
      const perPage = this.perPage;

      const from = (page * perPage) - perPage;
      const to = (page * perPage);

      return data.slice(from, to);
    },
    preDeleteItem(item, index) {
      this.preDeletedItem = {
        item,
        index,
      };

      this.showModal = true;
    },
    deleteItem() {
      this.$emit('deleteItem', this.preDeletedItem);

      this.showModal = false;
    },
    downloadItem(user) {
      this.$emit('downloadItem', user);
    },
  },
  watch: {
    data() {
      this.setPages();
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Para pesquisar, digite sua busca aqui..." v-model="searchBar" />
        </div>
      </div>
    </div>
    <table class="table tablesorter" :class="tableClass">
      <thead :class="theadClasses">
      <tr>
        <slot name="columns">
          <th v-for="column in columns" :key="column">{{column}}</th>
        </slot>
      </tr>
      </thead>
      <tbody :class="tbodyClasses">
        <router-link
          :to="`/investor-detail/${item.id}`"
          tag="tr"
          v-for="(item, index) in displayedData"
          :key="index"
        >
          <td>{{ item.dados_complementares.fullName }}</td>
          <td>{{ item.dados_complementares.user.email }}</td>
          <td>{{ item.dados_complementares.user.phone }}</td>
          <td
            :class="[item.dados_complementares.approvalStatus === 'Recusado' ? 'text-warning' : 'text-info']"
          >
            {{ item.dados_complementares.approvalStatus }}
          </td>

          <td v-if="hasActions" class="actions-column">
            <base-dropdown
              tag="div"
              :menu-on-right="!$rtl.isRTL"
              title-tag="a" class="nav-item"
            >
              <a slot="title" href="#" class="nav-link" data-toggle="dropdown">
                <i class="fas fa-ellipsis-h"></i>
              </a>

              <li class="nav-link">
                <router-link
                  :to="`edit-user/${item.email.email}`"
                  class="nav-item dropdown-item"
                >
                  Editar o Usuário
                </router-link>
              </li>
              <li class="nav-link">
                <a
                  @click="preDeleteItem(item, index)"
                  href="#"
                  class="nav-item dropdown-item"
                >
                  Excluir o Usuário
                </a>
              </li>
            </base-dropdown>
          </td>
        </router-link>
      </tbody>
    </table>

    <nav class="table-pagination">
      <ul class="pagination">
        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page != 1"
            @click="page--"
          >
            <i class="fas fa-angle-left"></i>
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
            :key="index"
            @click="page = pageNumber"
            :class="{ 'is-active': page === pageNumber }"
          >
            {{ pageNumber }}
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page < pages.length"
            @click="page++"
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </li>
      </ul>
    </nav>

    <confirmation-modal
      :show="showModal"
      @close="showModal = false"
      @confirmDelete="deleteItem()"
    ></confirmation-modal>
  </div>
</template>
