<script>
import {vueTopprogress} from 'vue-top-progress';
import attachmentsService from '@/services/Attachments/attachmentsService';

import AttachmentSuccess from '@/components/Notifications/Attachments/AttachmentSuccess';
import AttachmentError from '@/components/Notifications/Attachments/AttachmentError';

import {DoubleBounce} from 'vue-loading-spinner';
import logsService from '@/services/Logs/logsService';

export default {
  data() {
    return {
      isLoading: false,
      file: {
        selectedFilename: '',
        fileName: '',
      },
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters.isLoggedIn;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  components: {
    vueTopprogress,
    DoubleBounce,
  },
  methods: {
    async addNewAttachment() {
      this.$refs.topProgress.start();
      this.isLoading = true;

      try {
        const file = await this.$refs.uploadFile.files[0];

        const logUser = {
            user: {
                email: this.currentPessoa.email.email
            }
        };
        const logContent = {};
        logsService.createNewLog(logUser, '', 'Adicionar Outro Documento', '', logContent);

        await attachmentsService.addNewDocumentAttachment(file, this.file.fileName, 'InvestorDocuments', this.loggedUser.email);

        this.$notify({
          component: AttachmentSuccess,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.file = {};

        this.$emit('uploadDocument', 'OtherDocument');
        // this.$router.push('/profile');
      } catch (e) {
        this.$notify({
          component: AttachmentError,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.isLoading = false;
      this.$refs.topProgress.done();
    },
    selectedFile() {
      this.file.selectedFilename = this.$refs.uploadFile.files[0].name;
    },
  },
};
</script>

<template>
<div class="attachment-list">
  <card>
    <form @submit.prevent="addNewAttachment()">
        <h5 slot="header" class="title">
          Outro Documento
        </h5>

        <div class="row">
          <div class="col-md-12">
            <base-input
              v-model="file.fileName"
              label="Nome do Documento*"
              placeholder="Nome do Documento"
              required
            ></base-input>
          </div>

          <div class="col-md-12">
            <div class="file-uploader">
              <div v-if="!isLoading">
                <div class="upload-button">
                  <i slot="title" class="tim-icons icon-cloud-upload-94"></i>
                  <label for="upload-file">Selecionar..</label>
                  <input
                    @change="selectedFile()"
                    type="file"
                    name="file"
                    ref="uploadFile"
                    id="upload-file"
                    accept="image/*, video/*, .pdf, .doc, .xlsx"
                    required
                  />
                </div>
                <h2 v-if="!file.selectedFilename">
                  Clique aqui para adicionar um Anexo
                </h2>
                <h2 v-if="file.selectedFilename">
                  {{ file.selectedFilename }}
                  <i class="fas fa-times-circle" @click="file.selectedFilename = ''"></i>
                </h2>
              </div>

              <small v-if="!isLoading">
                *Serão aceitos apenas arquivos em formato de imagens, vídeos <b>.pdf</b> <b>.doc</b> e <b>.xlsx</b>
              </small>

              <double-bounce v-if="isLoading"></double-bounce>
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-custom">Upload</button>
      </form>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </card>
</div>
</template>
