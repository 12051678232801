<script>
import NotificationTemplate from '@/components/Notifications/General/NotificationTemplate';
import ProcessError from '@/components/Notifications/Login/ProcessError';
import EmailDeOutroProvedor from '@/components/Notifications/Login/EmailDeOutroProvedor';
import authenticationService from '@/services/Authentication/authenticationService.js';
import pessoasService from '@/services/Pessoas/pessoasService';
import { vueTopprogress } from 'vue-top-progress';
import logsService from '@/services/Logs/logsService';
import claimsService from '@/services/Authentication/claimsService';
import investorsService from '@/services/Investors/investorsService';
import Password from 'vue-password-strength-meter';

import Firebase from 'firebase/app';
const GoogleProvider = new Firebase.auth.GoogleAuthProvider();
const FacebookProvider = new Firebase.auth.FacebookAuthProvider();

import Vue from 'vue';

import { PessoaCaracteristica, CWLRedirectRef } from '@/store/cwl_enums';

import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

import store from '@/store/index';
import { email } from 'vuelidate/lib/validators';

export default {
  components: {
    vueTopprogress,
    Password,
  },
  data() {
    return {
      modalidade: process.env.VUE_APP_TYPE,
      user: {},
      envPath: process.env.VUE_APP_ROOT_SITE,
      logoUrl: require(process.env.VUE_APP_LOGO_URL),
      socialLoginButtonDisabled: false,
      facebookDisabled: process.env.VUE_APP_FACEBOOK_LOGIN_ENABLED === 'false',
    };
  },
  validations: {
    user: {
      email: {
        valid: email,
      },
    },
  },
  mounted() {
    try {
      const user = this.$store.getters.getPessoa;
      if (user && user.pessoa_id) {
        this.$router.push('/dashboard');
      }
    } catch (e) {}
  },
  computed: {
    appTitle() {
      return process.env.VUE_APP_TITLE;
    },
    registerUrl() {
      const redirect = this.$route.query.redirect
        ? 'redirect=' + this.$route.query.redirect
        : '';

      const value = '/register?' + redirect;
      return value;
    },
    loggedInUser() {
      return this.$store.getters.isLoggedIn;
    },
    investorLogged() {
      return this.$store.getters.isLoggedInvestor;
    },
    ref() {
      return this.$route.params.ref;
    },
  },
  methods: {
    redirectTo() {
      const ref = this.$route.params.ref || this.$route.query.ref;
      const id =
        this.$route.query.id || this.$store.getters.paymentCheckout.financingId;
      if (ref === 'site' || id) {
        const siteUrl = process.env.VUE_APP_FINANCING_PAGE.replace('{id}', id);
        window.top.location.href = siteUrl;
      } else if (ref == CWLRedirectRef.siteCriarCampanha) {
        this.$router.push('/add-financing');
      } else if (this.$route.query.redirect) {
        this.$router.push(this.$route.query.redirect);
      } else {
        this.$router.push('/dashboard');
      }
    },

    async loginWithCredentials() {
      this.$refs.topProgress.start();
      this.socialLoginButtonDisabled = true;

      const emailToLoggin = this.user.email.toLowerCase();

      try {
        const userFromFirebase =
          await authenticationService.loginWithCredentials(
            emailToLoggin,
            this.user.password,
          );

        this.handleLogin(userFromFirebase);
      } catch (error) {
        console.log('credentials login error', error);

        this.socialLoginButtonDisabled = false;
        this.$refs.topProgress.done();
        this.$notify({
          component: NotificationTemplate,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },

    async loginWithGoogle() {
      this.socialLoginButtonDisabled = true;
      this.$refs.topProgress.start();

      try {
        const firebaseUser = await Firebase.auth().signInWithPopup(
          GoogleProvider,
        );
        this.handleLogin(firebaseUser);
      } catch (error) {
        console.log('google auth error', error);
        this.socialLoginButtonDisabled = false;
        let comp = ProcessError;
        if (error.code == 'auth/account-exists-with-different-credential') {
          comp = EmailDeOutroProvedor;
        }

        this.$refs.topProgress.done();
        if (error.code != 'auth/popup-closed-by-user') {
          this.$notify({
            component: comp,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
        }
      }
    },

    async loginWithFacebook() {
      this.$refs.topProgress.start();
      this.socialLoginButtonDisabled = true;

      try {
        const authUser = await Firebase.auth().signInWithPopup(
          FacebookProvider,
        );
        this.handleLogin(authUser);
      } catch (error) {
        console.error('facebook login error', error);
        let comp = ProcessError;
        this.socialLoginButtonDisabled = false;
        if (error.code == 'auth/account-exists-with-different-credential') {
          comp = EmailDeOutroProvedor;
        }

        this.$refs.topProgress.done();
        if (error.code != 'auth/popup-closed-by-user') {
          this.$notify({
            component: comp,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
        }
      }
    },
    async handleLogin(authUser) {
      try {
        const role = await claimsService.getUserClaim(authUser.user);
        const user = await pessoasService.findCurrentUser();

        const redirect = this.$route.query.redirect
          ? 'redirect=' + this.$route.query.redirect
          : '';

        if (user && !role) {
          this.socialLoginButtonDisabled = false;
          this.$notify({
            title: 'Ooopss',
            message:
              'Encontramos uma inconsistência no seu login! Por favor, entre em contato com o administrador.',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 3000,
          });
          this.$router.push('/register?' + redirect);
        } else if (!user) {
          this.$store.commit('SET_USER_TEMP', authUser);

          this.$router.push('/register?' + redirect);
          this.$notify({
            title: 'Ooopss',
            message:
              'Você ainda não possui conta, por favor preencha os dados abaixo',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 3000,
          });
        } else {
          this.$store.dispatch('login', authUser);
          const objCooke = this.$CWLCreatePessoaForCookie(user);
          this.$store.commit('SET_PESSOA', objCooke);
          if (store.getters.userClaim.role === 'investor') {
            store.commit(
              'SET_TERM_OF_USE_ACCEPTED',
              user.dados_complementares.termsOfUseAccepted,
            );
          }

          this.user.password = '';
          logsService.createNewLog(
            authUser,
            'loginWithCredentials',
            'SignIn',
            'POST',
            this.user,
          );

          const title = 'Olá ' + user.nome + '!';
          this.$notify({
            title: title,
            message: 'Bem vindo de volta!',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 3000,
          });
          this.redirectTo();
        }
      } catch (error) {
        console.error('handle user error', error);
        this.socialLoginButtonDisabled = false;

        this.$refs.topProgress.done();
        this.$notify({
          component: ProcessError,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },

    navigateToSite() {
      window.top.location.href = this.envPath;
    },
    async createUser(userFromGoogle) {
      try {
        const user = {
          email: userFromGoogle.email,
        };

        const { uid, email } = userFromGoogle;

        user.maritalStatus = '';

        const pessoa = this.$CWLNewPessoaPadrao();
        pessoa.nome = userFromGoogle.displayName;
        pessoa.caracteristicas = [PessoaCaracteristica.Usuario];
        pessoa.dados_complementares = {
          user: {
            maritalStatus: '',
            email: email,
          },
          address: {
            state: '',
          },
          bank: {
            bankName: '',
          },
          profile: {
            personType: '',
            segment: '',
            value: '',
            profile: '',
          },
          fullName: userFromGoogle.displayName,
          status: 'empty-register',
          approvalStatus: 'Em Análise ',
          termsOfUseAccepted: true,
          termsOfUseDateAccepted: '',
        };
        pessoa.email.email = email;
        pessoa.emails.push(pessoa.email);

        const investorCreated = await investorsService.createNewInvestor(
          pessoa,
        );
        const investorFromFirebase = await investorsService.get(
          investorCreated.id,
        );
        const investor = investorFromFirebase.data();
        investor.id = investorFromFirebase.id;
        const objCooke = this.$CWLCreatePessoaForCookie(investor);
        this.$store.commit('SET_PESSOA', objCooke);

        await claimsService.setNewUserClaim(uid, email, 'investor');
        await claimsService.getUserClaim(userFromGoogle);

        const title = 'Olá ' + pessoa.nome + '!';
        this.$notify({
          title: title,
          message: 'Bem vindo de volta!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
        this.$refs.topProgress.done();
        this.redirectTo();
      } catch (e) {
        console.log(e);
        this.socialLoginButtonDisabled = false;
        this.$notify({
          component: ProcessError,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },
  },
};
</script>

<template>
  <div class="sign-in">
    <div class="card">
      <div class="back" style="margin-top: -30px !important">
        <i class="fa fa-arrow-left" @click="navigateToSite()"></i>
      </div>

      <!-- <img src="@/assets/images/adoisjota_brand_white.png" alt="Customer Logo" class="brand" style="width: 200px !important;" />
      <br /> -->
      <h1
        v-if="modalidade == 'equity'"
        style="
          font-size: 28px;
          color: #000;
          font-family: Poppins, Arial, Helvetica, sans-serif !important;
          font-weight: bold;
        "
      >
        CWL Equity
      </h1>
      <h1
        v-if="modalidade == 'doacao'"
        style="
          font-size: 28px;
          color: #000;
          font-family: Poppins, Arial, Helvetica, sans-serif !important;
          font-weight: bold;
        "
      >
        {{ appTitle }}
      </h1>
      <p>Para entrar, informe os dados abaixo:</p>

      <div class="social-network-buttons">
        <button
          @click="loginWithGoogle"
          class="btn btn-warning"
          :disabled="socialLoginButtonDisabled"
        >
          Entrar com Google
        </button>

        <button
          v-if="!facebookDisabled"
          @click="loginWithFacebook"
          class="btn btn-info"
          :disabled="socialLoginButtonDisabled"
        >
          Entrar com Facebook
        </button>
      </div>

      <form @submit.prevent="loginWithCredentials" class="login-form">
        <div class="form-group">
          <label for="" class="control-label">Email</label>
          <input
            v-model="user.email"
            data-testId="email"
            type="email"
            class="form-control"
            placeholder="email@example.com"
            required
          />
          <div class="error" v-if="user.email != '' && !$v.user.email.valid">
            Informe um e-mail válido.
          </div>
        </div>

        <div class="form-group">
          <label for="" class="control-label">Senha</label>
          <password
            v-model="user.password"
            :secureLength="6"
            :badge="false"
            :showStrengthMeter="false"
            :toggle="true"
            :defaultClass="'form-control'"
            required
          />
        </div>

        <div class="form-group sign-in-row">
          <label
            for=""
            :style="socialLoginButtonDisabled ? 'cursor: default;' : ''"
          >
            Não tem uma conta?
            <a
              :href="registerUrl"
              class="create-account"
              :style="
                socialLoginButtonDisabled
                  ? 'pointer-events: none; cursor: default;'
                  : ''
              "
              >Criar</a
            >
          </label>

          <label
            for=""
            class="forgot-password-link"
            :style="socialLoginButtonDisabled ? 'cursor: default;' : ''"
          >
            <router-link
              to="/forgot-password"
              :style="
                socialLoginButtonDisabled
                  ? 'pointer-events: none; cursor: default;'
                  : ''
              "
            >
              Esqueci minha senha
            </router-link>
          </label>
        </div>

        <button
          type="submit"
          class="btn btn-primary"
          :disabled="socialLoginButtonDisabled"
        >
          Entrar
        </button>
      </form>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
