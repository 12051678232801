var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-user"},[_c('card',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateUser()}}},[_c('h5',{staticClass:"title",attrs:{"slot":"header"},slot:"header"},[_vm._v("Meus Dados")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 pr-md-1"},[_c('base-input',{attrs:{"label":"Nome*","placeholder":"Informe o nome","required":""},model:{value:(_vm.nome),callback:function ($$v) {_vm.nome=$$v},expression:"nome"}})],1),_c('div',{staticClass:"col-md-4 pr-md-1"},[_c('div',{staticClass:"form-group"},[_c('base-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) ####-####', '(##) #####-####']),expression:"['(##) ####-####', '(##) #####-####']"}],attrs:{"label":"Celular*","placeholder":"Informe o melhor celular","required":""},model:{value:(_vm.telefone),callback:function ($$v) {_vm.telefone=$$v},expression:"telefone"}}),(!_vm.$v.telefone.valid)?_c('div',{staticClass:"error"},[_vm._v(" Informe um telefone com DDD válido. ")]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4 pl-md-1"},[_c('label',{staticClass:"control-label",attrs:{"for":""}},[_vm._v("Data de Nascimento")]),_c('br'),_c('birth-datepicker',{attrs:{"valueIsString":true,"high":true,"hideHeader":true,"attachment":'bottom',"delimiter":'/',"placeholder":'Informe a data de nascimento. Ex: 27/12/1993',"locale":[
              'Jan',
              'Fev',
              'Mar',
              'Abr',
              'Mai',
              'Jun',
              'Jul',
              'Ago',
              'Set',
              'Out',
              'Nov' ]},model:{value:(_vm.birthday),callback:function ($$v) {_vm.birthday=$$v},expression:"birthday"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 pr-md-3"},[_c('div',{staticClass:"form-group"},[_c('base-input',{attrs:{"label":"E-mail*","placeholder":"Informe o melhor e-mail","type":"email","disabled":""},model:{value:(_vm.currentPessoa.email.email),callback:function ($$v) {_vm.$set(_vm.currentPessoa.email, "email", $$v)},expression:"currentPessoa.email.email"}}),(!_vm.isValidEmail)?_c('div',{staticClass:"error"},[_vm._v(" Informe um e-mail válido. ")]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4 pr-md-3"},[_c('div',{staticClass:"form-group"},[(_vm.userRole != 'master')?_c('base-input',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##']),expression:"['###.###.###-##']"}],attrs:{"label":"CPF*","placeholder":"Informe o seu cpf","type":"cpf","disabled":""},model:{value:(_vm.currentPessoa.documento.numero),callback:function ($$v) {_vm.$set(_vm.currentPessoa.documento, "numero", $$v)},expression:"currentPessoa.documento.numero"}}):_vm._e(),(_vm.userRole == 'master')?_c('base-input',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##']),expression:"['###.###.###-##']"}],attrs:{"label":"CPF*","placeholder":"Informe o seu cpf","type":"cpf"},model:{value:(_vm.currentPessoa.documento.numero),callback:function ($$v) {_vm.$set(_vm.currentPessoa.documento, "numero", $$v)},expression:"currentPessoa.documento.numero"}}):_vm._e(),(!_vm.isValidCpf)?_c('div',{staticClass:"error"},[_vm._v("Informe um CPF válido.")]):_vm._e()],1)])]),_c('button',{staticClass:"btn btn-sm",attrs:{"type":"submit"}},[_vm._v("Salvar")]),_c('button',{staticClass:"btn btn-sm btn-warning",attrs:{"type":"button"},on:{"click":_vm.redefinirSenha}},[_vm._v(" Redefinir senha ")])])]),(
      _vm.modalidade == 'equity' &&
      _vm.userRole !== 'master' &&
      _vm.pessoa.email != undefined
    )?_c('div',{staticClass:"row profile"},[_c('div',{staticClass:"col-md-12"},[_c('edit-profile-form',{attrs:{"pessoa":_vm.pessoa,"model":_vm.model},on:{"saveProfileInformations":_vm.saveProfileInformations}})],1)]):_vm._e(),_c('vue-topprogress',{ref:"topProgress"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }